@tailwind components;
@tailwind utilities;

html {
  background: black;
}

body {
  background: black;
}
